<template>
  <div style="padding: 0px">
    <a-row
      style="height: 48px; background: black; padding: 0px 16px"
      align="middle"
      type="flex"
    >
      <a-col><div style="color: white; font-size: 24px">Categorie</div></a-col>
    </a-row>
    <a-row :gutter="12" style="padding: 0px 12px">
      <a-col :span="inEdit ? 16 : 24" style="padding: 12px">
        <a-row>
          <a-col style="padding: 12px 0">
            <a-button @click="newItem" type="primary">NUOVO</a-button>
          </a-col>
        </a-row>
        <div
          id="table"
          style="
            height: calc(100vh - 140px);
            background: white;
            border: 1px solid #aaaaaa;
          "
        ></div
      ></a-col>
      <a-col :span="inEdit ? 8 : 0">
        <div
          style="
            background: #fafafa;
            padding: 12px;
            box-sizing: border-box;
            overflow-y: scroll;
            height: calc(100vh - 48px);
          "
        >
          <a-row type="flex" align="middle" justify="space-between">
            <a-col><h3>Edita</h3></a-col>
            <a-col><a @click="chiudi">chiudi</a></a-col></a-row
          >

          <a-form
            id="components-form-demo-validate-other"
            :form="form"
            @submit="handleSubmit"
          >
            <a-form-item label="Nome">
              <a-input v-decorator="['name', { initialValue: 'Nome locale' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="Indirizzo">
              <a-input
                v-decorator="['indirizzo', { initialValue: 'Indirizzo' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="Descrizione">
              <a-textarea
                v-decorator="['descrizione', { initialValue: 'Descrizione' }]"
                style="height: 128px"
              >
              </a-textarea>
            </a-form-item>
            <a-form-item label="Città">
              <a-input v-decorator="['citta', { initialValue: 'Città' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="telefono">
              <a-input
                v-decorator="['telefono', { initialValue: '040 332244' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="www">
              <a-input v-decorator="['www', { initialValue: 'www.sito.com' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="facebook">
              <a-input
                v-decorator="['facebook', { initialValue: 'www.facebook.com' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="twitter">
              <a-input
                v-decorator="['twitter', { initialValue: 'www.twitter.com' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="instagram">
              <a-input
                v-decorator="[
                  'instagram',
                  { initialValue: 'www.instagram.com' },
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="Select" has-feedback>
              <a-select
                v-decorator="['tipologia', { initialValue: 'Ristorante' }]"
                placeholder="Tripologia"
              >
                <a-select-option value="Pasticceria">
                  Pasticceria
                </a-select-option>
                <a-select-option value="Ristorante">
                  Ristorante
                </a-select-option>
                <a-select-option value="Bar"> Bar </a-select-option>
                <a-select-option value="Servizi"> Servizi </a-select-option>
                <a-select-option value="Shop"> Shop </a-select-option>
                <a-select-option value="Altro"> Altro </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Orari">
              <a-input v-decorator="['orari', { initialValue: '' }]"> </a-input>
            </a-form-item>
            <a-form-item label="Collaborazione attiva">
              <a-switch
                v-decorator="[
                  'collaborazione_attiva',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
            <a-form-item label="Descrizione collaborazione">
              <a-textarea
                v-decorator="[
                  'descrizione_collaborazione',
                  { initialValue: 'Descrizione collaborazione' },
                ]"
                style="height: 128px"
              >
              </a-textarea>
            </a-form-item>
            <a-form-item label="Lat">
              <a-input v-decorator="['lat', { initialValue: 44.63 }]"></a-input>
            </a-form-item>

            <a-form-item label="Long">
              <a-input v-decorator="['lng', { initialValue: 13.8 }]" />
            </a-form-item>
            <a-form-item label="Upload" extra="">
              <a-upload
                list-type="picture-card"
                name="logo"
                multiple
                :customRequest="uploadfiles"
                v-decorator="[
                  'upload',
                  {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                  },
                ]"
              >
                <div v-if="fileList.length < 8">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
                <!-- <a-button> <a-icon type="upload" /> Click to upload </a-button> -->
              </a-upload>
            </a-form-item>

            <!-- 
             
               list-type="picture"
              
               <a-form-item label="Slider">
              <a-slider
                v-decorator="['slider']"
                :marks="{
                  0: 'A',
                  20: 'B',
                  40: 'C',
                  60: 'D',
                  80: 'E',
                  100: 'F',
                }"
              />
            </a-form-item>

            <a-form-item label="Radio.Group">
              <a-radio-group v-decorator="['radio-group']">
                <a-radio value="a"> item 1 </a-radio>
                <a-radio value="b"> item 2 </a-radio>
                <a-radio value="c"> item 3 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Radio.Button">
              <a-radio-group v-decorator="['radio-button']">
                <a-radio-button value="a"> item 1 </a-radio-button>
                <a-radio-button value="b"> item 2 </a-radio-button>
                <a-radio-button value="c"> item 3 </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Checkbox.Group">
              <a-checkbox-group
                v-decorator="['checkbox-group', { initialValue: ['A', 'B'] }]"
                style="width: 100%"
              >
                <a-row>
                  <a-col :span="8">
                    <a-checkbox value="A"> A </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox disabled value="B"> B </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="C"> C </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="D"> D </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="E"> E </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item> -->

            <!--  <a-form-item label="Rate">
              <a-rate
                v-decorator="['rate', { initialValue: 3.5 }]"
                allow-half
              />
            </a-form-item>

            <a-form-item
              label="Upload"
              extra="longgggggggggggggggggggggggggggggggggg"
            >
              <a-upload
                v-decorator="[
                  'upload',
                  {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                  },
                ]"
                name="logo"
                action="/upload.do"
                list-type="picture"
              >
                <a-button> <a-icon type="upload" /> Click to upload </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item label="Dragger">
              <div class="dropbox">
                <a-upload-dragger
                  v-decorator="[
                    'dragger',
                    {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                    },
                  ]"
                  name="files"
                  action="/upload.do"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </a-upload-dragger>
              </div>
            </a-form-item> -->

            <a-form-item
              style="width: 100%"
              :wrapper-col="{ span: 24, offset: 0 }"
            >
              <a-button style="width: 100%" type="primary" html-type="submit">
                Salva
              </a-button>
            </a-form-item>
          </a-form>
        </div></a-col
      ></a-row
    >
  </div>
</template>

<script>
const axios = require("axios");
import Tabulator from "tabulator-tables";
export default {
  watch: {
    categories() {
      this.table.setData(this.categories);
    },
  },
  async mounted() {
    this.macro_categories = (
      await axios.get("/vmuts/api/macro_categories")
    ).data;
    this.categories = (await axios.get("/vmuts/api/sub_categories")).data;

    let cats = {};
    this.macro_categories.map((c) => {
      cats[c] = c;
    });

    this.table = new Tabulator(this.$el.querySelector("#table"), {
      // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
      data: this.categories, //assign data to table

      layout: "fitColumns", //fit columns to width of table (optional)
      columns: [
        {
          cellEdited: async function (cell) {
            let currentItem = cell.getData();
            await axios.post("/vmuts/api/sub_category", currentItem);
            this.categories = (
              await axios.get("/vmuts/api/sub_categories")
            ).data;
            // this.$db.ref(`/sub_categories/${currentItem.key}`).update({
            //   name: currentItem.name,
            //   //type:currentItem.type
            // });
          }.bind(this),
          field: "name",
          editor: "input",
          title: "Nome",
          width: 128,
        },
        {
          field: "type",
          title: "Macro Categoria",
          width: 192,

          editor: "select",
          cellEdited: async function (cell) {
            let currentItem = cell.getData();
            await axios.post("/vmuts/api/sub_category", currentItem);
            this.categories = (
              await axios.get("/vmuts/api/sub_categories")
            ).data;
            // this.$db.ref(`/sub_categories/${currentItem.key}`).update({
            //   type: currentItem.type,
            // });
          }.bind(this),
          editorParams: {
            values: cats,
          },
        },

        {
          width: 88,
          field: "",
          title: "Azioni",
          hozAlign: "center",
          cellClick: async function (e, ev) {
            await axios.post("/vmuts/api/hidecategory", ev.getData());
            this.categories = (
              await axios.get("/vmuts/api/sub_categories")
            ).data;
            //this.$db.ref(`/sub_categories/${ev.getData().key}`).remove();
            e.stopPropagation();
          }.bind(this),
          formatter: function () {
            return `<button>Elimina</button>`;
          }.bind(this),
        },
      ],
      // rowClick: async function (e, row) {
      //   this.inEdit = true;
      //   this.currentItem = row.getData();

      //   if (this.currentItem.upload)
      //     for await (const img of this.currentItem.upload) {
      //       await new Promise((res, rej) => {
      //         this.$storage
      //           .ref()
      //           .child("images/" + img.uid)
      //           .getDownloadURL()
      //           .then(function (url) {
      //             img.url = url;
      //             //imgUrls.push(url);
      //             res();
      //           });
      //       });
      //     }
      //   else {
      //     this.currentItem.upload = [];
      //   }

      //   this.form.setFieldsValue({
      //     ...this.currentItem,

      //     lat: +this.currentItem.location.geometry.coordinates[1],
      //     lng: +this.currentItem.location.geometry.coordinates[0],
      //   });
      //   //trigger an alert message when the row is clicked
      // }.bind(this),
    });
  },
  methods: {
    async newItem() {
      await axios.post("/vmuts/api/sub_category", {
        type: null,
        name: "Nuova cat",
      });
      this.categories = (await axios.get("/vmuts/api/sub_categories")).data;
      // this.$sub_categories.push({
      //   type: null,
      //   name: "Nuova",
      // });
    },
    __handleSubmit(e) {
      this.inEdit = false;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let upload = [];
          if (values.upload != undefined)
            values.upload.map((q) => {
              upload.push({ uid: q.uid });
            });

          values.upload = [...upload];

          if (this.currentItem.key) {
            // this.$db.ref(`/places/${this.currentItem.key}`).update({
            //   ...values,
            //   internalId: this.internalId,
            //   updateDate: new Date(),
            //   location: {
            //     type: "Feature",
            //     geometry: {
            //       type: "Point",
            //       coordinates: [+values.lng, +values.lat],
            //     },
            //     properties: {},
            //   },
            // });
          } else {
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
  data() {
    return {
      macro_categories: [],
      categories: [],
      defaultFileList: [],
      fileList: [],
      internalId: null,
      inEdit: false,
      currentItem: {
        collaborazione_attiva: false,
        upload: [],
      },
      labelPosition: "on-border",
    };
  },
};
</script>
<style>
button {
  height: 22px;
  line-height: 12px !important;
  font-size: 12px;
  outline: none;
}
.tabulator-row {
}
.tabulator-row .tabulator-cell {
  padding: 0px 4px;
}
#table {
  width: 100%;
}
</style>
